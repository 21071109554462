import { BUILDING_IMAGE_PLACEMENT_DATA_TYPE, ROOF_PLANE_DATA_TYPE } from "../../../../da/map/data-types";
import DaRoofPlanes from "../../../../da/map/interaction-managers/select/roof-planes";
import { modifyRoofPlane, deleteRoofPlane } from "../../modification-helpers/roof-plane";
import { updateProjectOriginAndCartesianCoordinatesForChildren } from "../../modification-helpers/project";

export default class RoofPlanes extends DaRoofPlanes {
  constructor(controller) {
    super(controller);

    this.modifyRoofPlane = modifyRoofPlane;
    this.deleteRoofPlane = deleteRoofPlane;
  }

  filterSelects = (feature, _layer) => {
    const dataType = feature.get("dataType");

    // Building image selection is handled by the building image interaction manager
    if (!dataType || dataType === BUILDING_IMAGE_PLACEMENT_DATA_TYPE) return false;
    if (dataType !== ROOF_PLANE_DATA_TYPE) return true;

    const roofPlane = this.controller.mapModelSynchronizer.getRoofPlaneForFeature(
      feature,
      "PrRoofPlanesSelectInteractionManager#filterSelects",
    );
    return !roofPlane.modifyingTraceLocked;
  };

  removeSelectedFeatures(event) {
    const selectedFeaturesExist = this.currentSelectInteraction && this.selectedFeatures.length !== 0;

    super.removeSelectedFeatures(event);

    if (selectedFeaturesExist) {
      updateProjectOriginAndCartesianCoordinatesForChildren(this.controller.project);
    }
  }
}
